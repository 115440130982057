import React, { Fragment } from 'react';
import { parseAsContent } from 'utils/parser';

import {
  TitleStyled,
  TableStyled,
  TrStyled,
  TdTitleStyled,
  TdDetailStyled,
  AdditionalTextStyled,
} from './ProductDetailNutrientsTableStyled';

export default function ProductDetailNutrientsTable({ nutrientsTable }) {
  return nutrientsTable.map(
    ({
      nutrientsTable,
      productNutrientsTableTitle,
      productNutrientsAdditionalText,
    }) => (
      <Fragment key={productNutrientsTableTitle}>
        {productNutrientsTableTitle && (
          <TitleStyled>{productNutrientsTableTitle}</TitleStyled>
        )}
        {nutrientsTable && (
          <TableStyled>
            <tbody>
              {nutrientsTable.map(({ columnTitle, columnDetail }) => (
                <TrStyled key={`${columnTitle}-${columnDetail}`}>
                  <TdTitleStyled>{columnTitle}</TdTitleStyled>
                  <TdDetailStyled>
                    {parseAsContent(columnDetail, { onlyBasicTags: true })}
                  </TdDetailStyled>
                </TrStyled>
              ))}
            </tbody>
          </TableStyled>
        )}
        {productNutrientsAdditionalText && (
          <AdditionalTextStyled>
            {productNutrientsAdditionalText}
          </AdditionalTextStyled>
        )}
      </Fragment>
    ),
  );
}

ProductDetailNutrientsTable.propTypes = {};
