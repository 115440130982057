import posed from 'react-pose';

const BOTTLE_SPRING = {
  type: 'spring',
  stiffness: 80,
  damping: 9,
  mass: 1.5,
};

const BOTTLE_TRANSITION = {
  rotate: BOTTLE_SPRING,
  y: BOTTLE_SPRING,
};

const BOTTLE_SWITCH_SPRING = {
  type: 'spring',
  stiffness: 130,
  damping: 9,
  mass: 1.8,
};

const BOTTLE_SWITCH_TRANSITION = {
  rotate: BOTTLE_SWITCH_SPRING,
  opacity: { type: 'tween', duration: 200, ease: 'linear' },
  scale: { type: 'tween', duration: 0, ease: 'linear' },
};

const SCENERY_SPRING = {
  type: 'spring',
  stiffness: 100,
  damping: 9,
  mass: 1.5,
};

const SCENERY_SPRING_SCALE = {
  type: 'spring',
  stiffness: 150,
  damping: 10,
  mass: 1,
};

const SCENERY_TRANSITION = {
  rotate: SCENERY_SPRING,
  y: SCENERY_SPRING,
  scale: SCENERY_SPRING_SCALE,
};

const UNDER_DAMPED_SPRING = {
  stiffness: 500,
  damping: 25,
  restDelta: 0.5,
  restSpeed: 10,
};

const TOP_TRANSITION = {
  rotate: UNDER_DAMPED_SPRING,
  y: UNDER_DAMPED_SPRING,
  scale: UNDER_DAMPED_SPRING,
};

export const BottleSwitchPosed = posed.div({
  enter: {
    opacity: 1,
    rotate: '0deg',
    scale: 1,
    originX: '50%',
    originY: '50%',
    transition: BOTTLE_SWITCH_TRANSITION,
  },
  exit: {
    opacity: 0,
    rotate: '2deg',
    scale: 1,
    originX: '50%',
    originY: '50%',
    transition: BOTTLE_SWITCH_TRANSITION,
  },
});

export const BottlePosed = posed.div({
  top: {
    rotate: '0deg',
    y: 50,
    x: 0,
    scale: 1,
    transition: TOP_TRANSITION,
    // top uses default transition to be more snappy
  },
  top_mobile: {
    rotate: '0deg',
    y: 50,
    x: 0,
    scale: 1,
    transition: TOP_TRANSITION,
  },
  hop: {
    rotate: '-10deg',
    y: 0,
    x: 5,
    scale: 1.2,
    transition: BOTTLE_TRANSITION,
  },
  hop_mobile: {
    rotate: '-10deg',
    y: 0,
    x: 5,
    scale: 1.2,
    transition: BOTTLE_TRANSITION,
  },
  barley: {
    rotate: '-7deg',
    y: 0,
    x: -5,
    scale: 1.22,
    transition: BOTTLE_TRANSITION,
  },
  barley_mobile: {
    rotate: '7deg',
    y: 0,
    x: 5,
    scale: 1.22,
    transition: BOTTLE_TRANSITION,
  },
  water: {
    rotate: '-12deg',
    y: 2,
    x: 0,
    scale: 1.24,
    transition: BOTTLE_TRANSITION,
  },
  water_mobile: {
    rotate: '-15deg',
    y: 2,
    x: 0,
    scale: 1.24,
    transition: BOTTLE_TRANSITION,
  },
  nutrients: {
    rotate: '-9deg',
    y: -2,
    x: -10,
    scale: 1.22,
    transition: BOTTLE_TRANSITION,
  },
  nutrients_mobile: {
    rotate: '-5deg',
    y: -2,
    x: -10,
    scale: 1.22,
    transition: BOTTLE_TRANSITION,
  },
});

export const sceneryLayers = [
  posed.div({
    top: {
      rotate: '0deg',
      y: 50,
      opacity: 0,
      scale: 0.3,
      transition: TOP_TRANSITION,
    },
    hop: {
      rotate: '0deg',
      y: 0,
      opacity: 1,
      scale: 1.1,
      transition: SCENERY_TRANSITION,
      delay: 50,
    },
    barley: {
      rotate: '5deg',
      y: 0,
      opacity: 1,
      scale: 1,
      transition: SCENERY_TRANSITION,
      delay: 50,
    },
    water: {
      rotate: '3deg',
      y: 0,
      opacity: 1,
      scale: 1.1,
      transition: SCENERY_TRANSITION,
      delay: 50,
    },
    nutrients: {
      rotate: '0deg',
      y: 0,
      opacity: 1,
      scale: 1,
      transition: SCENERY_TRANSITION,
      delay: 50,
    },
  }),

  posed.div({
    top: {
      rotate: '0deg',
      y: 50,
      opacity: 0,
      scale: 0.3,
      transition: TOP_TRANSITION,
    },
    hop: {
      rotate: '0deg',
      y: 0,
      opacity: 1,
      scale: 1.05,
      transition: SCENERY_TRANSITION,
      delay: 75,
    },
    barley: {
      rotate: '5deg',
      y: 0,
      opacity: 1,
      scale: 1,
      transition: SCENERY_TRANSITION,
      delay: 75,
    },
    water: {
      rotate: '3deg',
      y: 0,
      opacity: 1,
      scale: 1.05,
      transition: SCENERY_TRANSITION,
      delay: 75,
    },
    nutrients: {
      rotate: '0deg',
      y: 0,
      opacity: 1,
      scale: 1,
      transition: SCENERY_TRANSITION,
      delay: 75,
    },
  }),

  posed.div({
    top: {
      rotate: '0deg',
      y: 50,
      opacity: 0,
      scale: 0.3,
      transition: TOP_TRANSITION,
    },
    hop: {
      rotate: '0deg',
      y: 0,
      opacity: 1,
      scale: 1.03,
      transition: SCENERY_TRANSITION,
      delay: 100,
    },
    barley: {
      rotate: '5deg',
      y: 0,
      opacity: 1,
      scale: 1,
      transition: SCENERY_TRANSITION,
      delay: 100,
    },
    water: {
      rotate: '3deg',
      y: 0,
      opacity: 1,
      scale: 1.03,
      transition: SCENERY_TRANSITION,
      delay: 100,
    },
    nutrients: {
      rotate: '0deg',
      y: 0,
      opacity: 1,
      scale: 1,
      transition: SCENERY_TRANSITION,
      delay: 100,
    },
  }),

  posed.div({
    top: {
      rotate: '0deg',
      y: 50,
      opacity: 0,
      scale: 0.3,
      transition: TOP_TRANSITION,
    },
    hop: {
      rotate: '0deg',
      y: 0,
      opacity: 1,
      scale: 1.02,
      transition: SCENERY_TRANSITION,
      delay: 125,
    },
    barley: {
      rotate: '5deg',
      y: 0,
      opacity: 1,
      scale: 1,
      transition: SCENERY_TRANSITION,
      delay: 125,
    },
    water: {
      rotate: '3deg',
      y: 0,
      opacity: 1,
      scale: 1.02,
      transition: SCENERY_TRANSITION,
      delay: 125,
    },
    nutrients: {
      rotate: '0deg',
      y: 0,
      opacity: 1,
      scale: 1,
      transition: SCENERY_TRANSITION,
      delay: 125,
    },
  }),

  posed.div({
    top: {
      rotate: '0deg',
      y: 50,
      opacity: 0,
      scale: 0.5,
      transition: TOP_TRANSITION,
    },
    hop: {
      rotate: '0deg',
      y: 0,
      opacity: 1,
      scale: 1.01,
      transition: SCENERY_TRANSITION,
      delay: 25,
    },
    barley: {
      rotate: '5deg',
      y: 0,
      opacity: 1,
      scale: 1,
      transition: SCENERY_TRANSITION,
      delay: 25,
    },
    water: {
      rotate: '3deg',
      y: 0,
      opacity: 1,
      scale: 1.01,
      transition: SCENERY_TRANSITION,
      delay: 25,
    },
    nutrients: {
      rotate: '0deg',
      y: 0,
      opacity: 1,
      scale: 1,
      transition: SCENERY_TRANSITION,
      delay: 25,
    },
  }),
];
