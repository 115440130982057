/* eslint-disable react/destructuring-assignment */
import React from 'react';
import scrollToElement from 'scroll-to-element';

import {
  ContainerStyled,
  DotStyled,
  DotInnerStyled,
  DotOuterStyled,
} from './ProductDotNavigationStyled';

export default function ProductDotNavigation({ sections, currentSection }) {
  return (
    <ContainerStyled hidden={currentSection.key === sections[0].key}>
      {sections.map(({ key, slug }, index) => {
        const isActive = currentSection.key === key;

        return index !== 0 && index !== sections.length - 1 ? (
          <DotStyled
            key={key}
            isActive={isActive}
            onClick={() => {
              scrollToElement(`#${slug}`);
            }}
          >
            <DotInnerStyled isActive={isActive} />
            <DotOuterStyled isActive={isActive} />
          </DotStyled>
        ) : null;
      })}
    </ContainerStyled>
  );
}

ProductDotNavigation.propTypes = {};
