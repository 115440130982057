import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

export const ContainerStyled = styled('div')(
  {
    display: 'flex',
    position: 'fixed',
    width: 20,
    right: 12,
    [media('tabletFluid')]: {
      right: 60,
    },
    [media('desktop')]: {
      right: 100,
    },
    top: '50%',
    flexDirection: 'column',
    transform: 'translateY(-50%)',
    opacity: 1,
    transition: 'opacity 400ms ease-in-out',
    backfaceVisibility: 'hidden',
    willChange: 'opactiy',
  },
  ({ hidden }) => ({
    opacity: hidden ? 0 : undefined,
    pointerEvents: hidden ? 'none' : undefined,
  }),
);

export const DotStyled = styled('div')(
  {
    height: 12,
    width: 12,
    marginTop: 26,
    position: 'relative',
    transition: 'opacity 100ms ease-in-out, transform 100ms ease-in-out',
    cursor: 'pointer',
    backfaceVisibility: 'hidden',
    willChange: 'transform, opactiy',

    ':hover': {
      opacity: 1,
      transform: 'scale(1.15)',
    },

    ':first-of-type': {
      marginTop: 0,
    },
  },
  ({ isActive }) => ({
    opacity: isActive ? 1 : undefined,
    ':hover': {
      transform: isActive ? 'scale(1)' : undefined,
    },
  }),
);

export const DotInnerStyled = styled('div')(
  {
    position: 'absolute',
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    transformOrigin: '50% 50%',
    transition: 'transform 400ms cubic-bezier(0.9, -0.3, 0, 1.3)',
    backfaceVisibility: 'hidden',
    willChange: 'transform',
  },
  ({ isActive }) => ({
    transform: `scale(${isActive ? 1 : 0})`,
  }),
);

export const DotOuterStyled = styled('div')({
  position: 'absolute',
  height: '100%',
  width: '100%',
  borderRadius: '50%',
  border: '1px solid currentColor',
});
