import posed from 'react-pose';

export const GradientPosed = posed.div({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
});
