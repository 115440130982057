/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useState, useEffect } from 'react';
import scrollToElement from 'scroll-to-element';
import { PoseGroup } from 'react-pose';

import {
  StageStyled,
  BackgroundStyled,
  BackgroundImageStyled,
  SwitcherStyled,
  GradientStyled,
  SceneryStyled,
} from './ProductStageStyled';
import { GradientPosed } from './ProductStagePosed';
import ProductSwitcher from '../ProductSwitcher';
import ProductScenery from '../ProductScenery';
import ProductDetails from '../ProductDetails';
import ProductDotNavigation from '../ProductDotNavigation';
import ProductBackButton from '../ProductBackButton';

export default function ProductStage({
  products,
  slug,
  // shortDescription,
  sceneryImages,
  sections,
  sectionSlugs,
  gradient,
  initialSectionKey,
  backgroundImage,
  topKeys,
}) {
  const [currentSection, setCurrentSection] = useState(() => ({
    key: initialSectionKey,
    $key: initialSectionKey,
  }));

  useEffect(() => {
    scrollToElement(`#${sectionSlugs[initialSectionKey]}`, { duration: 0 });
  }, []);

  const handleScrollIntoView = useCallback(
    $key => {
      // remove $ from secret scroll to top key
      const key = $key.replace(/^\$/, '');

      if (key !== currentSection) {
        setCurrentSection({
          $key,
          key,
        });

        // Disable product deeplinks as temporary workaround for bug WAR-248. ~RS
        // window.history.pushState(undefined, undefined, `#${sectionSlugs[key]}`);
      }
    },
    [currentSection, setCurrentSection, sectionSlugs],
  );

  const isAbleToScroll = currentSection.$key !== sections[0].key;
  const isStageExtended = currentSection.key !== sections[0].key;

  return (
    <StageStyled>
      <BackgroundStyled>
        <BackgroundImageStyled src={backgroundImage} />
      </BackgroundStyled>
      <SwitcherStyled isStageExtended={isStageExtended}>
        <ProductSwitcher
          products={products}
          currentSlug={slug}
          currentSection={currentSection}
        />
      </SwitcherStyled>
      <PoseGroup>
        <GradientPosed key={slug}>
          <GradientStyled
            gradient={gradient}
            isStageExtended={isStageExtended}
          />
        </GradientPosed>
      </PoseGroup>
      <ProductDetails
        gradient={gradient}
        sections={sections}
        slug={slug}
        onScrollIntoView={handleScrollIntoView}
        isAbleToScroll={isAbleToScroll}
        topKeys={topKeys}
        sceneryImages={sceneryImages}
      />
      <SceneryStyled isStageExtended={isStageExtended}>
        <ProductScenery
          slug={slug}
          sceneryImages={sceneryImages}
          currentSection={currentSection}
          isStageExtended={isStageExtended}
        />
      </SceneryStyled>
      <ProductDotNavigation
        sections={sections}
        currentSection={currentSection}
      />
      <ProductBackButton
        topSection={sections[0]}
        currentSection={currentSection}
      />
    </StageStyled>
  );
}
