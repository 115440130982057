import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

import { sizes } from 'components/common/Layout/variables';

export const DetailSectionStyled = styled('section')(
  {
    display: 'grid',
    paddingTop: sizes.spaceDouble,
    paddingRight: sizes.space,
    pointerEvents: 'all',

    [media('tabletFluid')]: {
      minHeight: '100vh',
      paddingTop: 120,
      paddingBottom: 75,
      paddingRight: 100,

      '> div': {
        margin: 'auto 0',
      },
    },

    [media('desktop')]: {
      paddingRight: sizes.space * 2,
    },
  },
  ({ isTopSection }) =>
    isTopSection && {
      paddingTop: 450,

      [media('tabletFluid')]: {
        paddingTop: 100,
        paddingRight: 0,
      },
    },
  // hide last section on mobile devices
  ({ lastChild }) =>
    lastChild && {
      display: 'none',

      [media('tabletFluid')]: {
        display: 'block',
      },
    },
);

export const ScrollTargetStyled = styled('div')(
  {
    position: 'absolute',
    height: '50%',
    top: 300,
    width: 10,
    pointerEvents: 'none',
    // uncomment to debug scroll targets:
    // backgroundColor: 'hotpink',
  },
  ({ firstChild, lastChild }) => ({
    height: firstChild || lastChild ? 2 : undefined,
    top: lastChild ? '75vh' : firstChild ? 0 : undefined,
  }),
);

export const ScrollToTopTargetStyled = styled('div')({
  position: 'absolute',
  height: 1,
  width: '100%',
  left: 0,
  top: '100vh',
  pointerEvents: 'none',
  // hide last section scroll to top target on mobile devices
  display: 'none',
  [media('tabletFluid')]: {
    display: 'block',
  },
});

export const ProductSceneryWrapStyled = styled('div')(
  {
    position: 'relative',
    width: '85%',
    overflow: 'hidden',
    transform: 'scale(1.2)',
    margin: '20px auto',

    '&::before': {
      display: 'block',
      content: '""',
      width: '100%',
      paddingTop: '100%',
    },

    [media('tabletFluid')]: {
      display: 'none',

      '&::before': {
        display: 'none',
      },
    },
  },
  (props) =>
    props.isTopSection && {
      display: 'none',
    },
);
