import { keyframes } from 'emotion';
import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

const wobble = keyframes`
  0%  { transform:  translate(-50%, -50%) rotate(0deg); }
  33%  { transform:  translate(-50.5%, -49.9%) rotate(.5deg); }
  66%  { transform:  translate(-49.5%, -50.1%) rotate(-.5deg); }
  100%  { transform:  translate(-50%, -50%) rotate(0deg); }
`;

const superWobble = keyframes`
  0%  { transform:  translate(-50%, -50%) rotate(0deg); }
  33%  { transform:  translate(-55%, -49.9%) rotate(.5deg); }
  66%  { transform:  translate(-45%, -50.2%) rotate(-.5deg); }
  100%  { transform:  translate(-50%, -50%) rotate(0deg); }
`;

export const AbsoluteCenterStyled = styled('div')(
  {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%) rotate(0deg)',
    animationName: superWobble,
    animationDuration: '15s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationFillMode: 'both',
    backfaceVisibility: 'hidden',

    [media('tabletFluid')]: {
      animationName: wobble,
      animationDuration: '15s',
    },
  },
  ({ animationDelay, animationPlayState }) => ({
    animationDelay,
    animationPlayState,
  }),
);
