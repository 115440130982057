import React from 'react';
import { slugify } from 'utils/slugify';

import {
  TilesStyled,
  TileStyled,
  ImgWrapperStyled,
  ImgStyled,
  LabelStyled,
} from './ProductDetailContainersStyled';

export default function ProductDetailContainers({ productBeerContainers }) {
  return (
    <TilesStyled>
      {productBeerContainers.map(
        ({ title, beerContainerLabel, beerContainerIcon }) => (
          <TileStyled key={slugify(title)}>
            <ImgWrapperStyled>
              {beerContainerIcon?.[0] && (
                <ImgStyled
                  src={beerContainerIcon?.[0].url}
                  alt={beerContainerLabel}
                />
              )}
            </ImgWrapperStyled>
            <LabelStyled>{beerContainerLabel}</LabelStyled>
          </TileStyled>
        ),
      )}
    </TilesStyled>
  );
}

ProductDetailContainers.propTypes = {};
