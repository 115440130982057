import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

export const TilesStyled = styled('div')({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  margin: '30px 0',
});

export const TileStyled = styled('div')({
  textTransform: 'uppercase',
  fontSize: 'inherit',
  width: 'calc(100% / 2)',
  display: 'inline-block',
  marginBottom: '1rem',

  [media('tablet')]: {
    width: 'calc(100% / 3)',
  },
});

export const ImgWrapperStyled = styled('div')({
  position: 'relative',
  marginBottom: '.5rem',
  maxWidth: 160,
  margin: '0 auto .5rem',

  '&::before': {
    display: 'block',
    content: '""',
    width: '100%',
    paddingTop: '100%',
  },
});

export const ImgStyled = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
});

export const LabelStyled = styled('h5')({}, (props) => ({
  fontFamily: props.theme.fontSecondary,
  fontWeight: 300,
  fontSize: 20,
  lineHeight: 1.2,
  padding: '0 1rem',
  textAlign: 'center',
}));
