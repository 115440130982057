/* eslint-disable react/destructuring-assignment */
import React from 'react';
import scrollToElement from 'scroll-to-element';

import GridWrap from 'components/common/GridWrap';
import Icon from 'components/common/Icon';

import { ContainerStyled, BackButtonStyled } from './ProductBackButtonStyled';

export default function ProductBackButton({ topSection, currentSection }) {
  return (
    <ContainerStyled hidden={currentSection.key === topSection.key}>
      <GridWrap>
        <BackButtonStyled
          type="button"
          onClick={() => {
            scrollToElement(`#${topSection.slug}`, { duration: 333 });
          }}
        >
          <Icon type="backArrow" />
        </BackButtonStyled>
      </GridWrap>
    </ContainerStyled>
  );
}

ProductBackButton.propTypes = {};
