import styled from 'utils/emotion';
import media from 'utils/mediaqueries';
import Image from 'components/common/Image';

import { PRODUCT_SWITCHER_BREAKPOINT } from '../ProductSwitcher/ProductSwitcherStyled';

export const StageStyled = styled('div')({
  position: 'relative',
});

export const BackgroundStyled = styled('div')({
  position: 'fixed',
  width: '50vw',
  height: 'auto',
  top: '0',
  left: '0',
});

export const BackgroundImageStyled = styled(Image)({
  width: '100%',
  height: '100%',
  position: 'absolute',
});

export const SwitcherStyled = styled('div')(
  {
    position: 'absolute',
    // height: '480px',
    width: '100%',
    top: 0,
    // paddingTop: 120,
    zIndex: 1,
    // backgroundColor: 'darkseagreen',

    [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
      zIndex: 'auto',
      position: 'fixed',
      paddingTop: 0,
      height: '100vh',
      transition: 'opacity 200ms ease-in-out, visibility 0s 200ms',
      backfaceVisibility: 'hidden',
      willChange: 'opacity, visibility',
    },
  },
  ({ isStageExtended }) => ({
    [media(PRODUCT_SWITCHER_BREAKPOINT)]: {
      visibility: isStageExtended ? 'hidden' : 'visible',
      opacity: isStageExtended ? 0 : 1,
    },
  }),
);

export const GradientStyled = styled('div')(
  {
    // display: 'none',
    position: 'fixed',
    transition:
      'transform 400ms cubic-bezier(0.9, -0.1, 0, 1.1), height 500ms ease-in',
    left: 0,
    bottom: 0,
    width: '100vw',
    pointerEvents: 'none',
    backfaceVisibility: 'hidden',
    willChange: 'transform',

    [media('tabletFluid')]: {
      display: 'block',
      transition: 'transform 400ms cubic-bezier(0.9, -0.1, 0, 1.1)',
      left: 'auto',
      bottom: 'auto',
      right: 0,
      top: 0,
      width: '80vw',
    },
  },
  ({ isStageExtended, gradient }) => ({
    height: '100vh',
    backgroundColor: gradient ? gradient[0] : 'transparent',
    background: gradient
      ? `linear-gradient(-45deg, ${gradient[1]} 0%, ${gradient[0]} 100%);`
      : undefined,

    [media('tabletFluid')]: {
      height: '100vh',
      transform: `translateX(${
        isStageExtended ? '3vw' : '30vw'
      }) translateZ(0)`,
    },
  }),
);

export const SceneryStyled = styled('div')(
  {
    display: 'none',
    [media('tabletFluid')]: {
      display: 'block',
    },
    transition: 'transform 400ms cubic-bezier(0.9, -0.15, 0, 1.15) 20ms',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    backfaceVisibility: 'hidden',
    willChange: 'transform',
  },
  ({ isStageExtended }) => ({
    transform: `translateX(${isStageExtended ? '-27vw' : 0}) translateZ(0)`,
  }),
);
