import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import TextTeaser from 'components/common/TextTeaser';
import { useIntersectionObserver } from 'utils/hooks/useIntersectionObserver';
import { parseAsText } from 'utils/parser';

import {
  DetailSectionStyled,
  ScrollToTopTargetStyled,
  ScrollTargetStyled,
  ProductSceneryWrapStyled,
} from './ProductDetailSectionStyled';
import ProductDetailNutrientsTable from '../ProductDetailNutrientsTable';
import ProductDetailContainers from '../ProductDetailContainers';
import ProductScenery from '../ProductScenery';

export default function ProductDetails({
  _key,
  slug,
  title,
  description,
  topline,
  badges,
  onScrollIntoView,
  isTopSection,
  textTeaserProps,
  productBeerContainers,
  nutrientsTable,
  firstChild,
  lastChild,
  sceneryImages,
}) {
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);

  const scrollTarget = useRef(null);

  function handleIntersect([{ isIntersecting }]) {
    if (isIntersecting) {
      onScrollIntoView(_key);
      if (!hasScrolledIntoView) {
        setHasScrolledIntoView(true);
      }
    }
  }
  useIntersectionObserver({
    target: scrollTarget,
    onIntersect: handleIntersect,
    rootMargin: '7px',
  });

  const lastSectionTarget = useRef(null);

  function handleBottomIntersect([{ isIntersecting }]) {
    if (isIntersecting) {
      window.scrollTo(0, 0);
    }
  }

  useIntersectionObserver({
    target: lastSectionTarget,
    onIntersect: handleBottomIntersect,
    rootMargin: '1px',
  });

  // Determine content.
  let content = null;

  if (nutrientsTable) {
    content = <ProductDetailNutrientsTable nutrientsTable={nutrientsTable} />;
  } else if (productBeerContainers) {
    content = (
      <>
        {parseAsText(description)}
        <ProductDetailContainers
          productBeerContainers={productBeerContainers}
        />
      </>
    );
  } else {
    content = description;
  }

  return (
    <>
      {lastChild ? <ScrollToTopTargetStyled ref={lastSectionTarget} /> : null}
      <DetailSectionStyled
        id={slug}
        isTopSection={isTopSection}
        lastChild={lastChild}
      >
        <ScrollTargetStyled
          ref={scrollTarget}
          lastChild={lastChild}
          firstChild={firstChild}
        />
        {!nutrientsTable && (
          <ProductSceneryWrapStyled isTopSection={isTopSection}>
            <ProductScenery
              slug={slug}
              sceneryImages={sceneryImages}
              currentSection={{ key: _key }}
              isStageExtended
              isMobile
            />
          </ProductSceneryWrapStyled>
        )}
        {title || description || nutrientsTable || productBeerContainers ? (
          <TextTeaser
            headline={title}
            topline={topline}
            badges={badges}
            noGold
            text={content}
            hasScrolledIntoView={lastChild ? true : hasScrolledIntoView}
            isHeadlineLarge
            {...textTeaserProps}
          />
        ) : null}
      </DetailSectionStyled>
    </>
  );
}

ProductDetails.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  description: PropTypes.any.isRequired,
  onScrollIntoView: PropTypes.func.isRequired,
};
