import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

export const ContainerStyled = styled('div')(
  {
    position: 'fixed',
    bottom: 25,
    left: 0,
    height: 39,
    width: '100%',
    pointerEvents: 'none',
    opacity: 1,
    transition: 'opacity 400ms ease-in-out',
    backfaceVisibility: 'hidden',
    willChange: 'opactiy',
    display: 'inline-block',

    [media('tabletFluid')]: {
      bottom: 100,
      display: 'inline-block',
    },
  },
  ({ hidden }) => ({
    opacity: hidden ? 0 : undefined,
    pointerEvents: hidden ? 'none' : undefined,
  }),
);

export const BackButtonStyled = styled('div')({
  height: 39,
  width: 39,
  color: 'white',
  pointerEvents: 'all',
  transition: 'color 200ms ease-in-out',
  cursor: 'pointer',
  position: 'absolute',
  right: 0,
  transform: 'rotate(90deg)',
  [media('tabletFluid')]: {
    right: 'auto',
    transform: 'initial',
  },
  '&:hover': {
    color: 'rgba(255, 255, 255, 0.91)',
  },
  svg: {
    transition: 'transform 100ms ease-in-out',
  },
  '&:hover svg': {
    transform: 'translateX(-2px)',
  },
});
